import React from "react"
function Footer(props) {
  return (
    <footer
    style={{
      margin: '0 auto',
      paddingTop: "4em",
      paddingBottom: "4em",
      backgroundColor: '#3D372E',
      color: '#fff',
      textAlign: 'center',
    }}
    >
    <div class="dan-orange container mx-auto p-8">
      <h4>No idea what goes here</h4>
    </div>

    </footer>
  )
}
export default Footer
