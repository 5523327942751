import React from "react"
function Callout (props) {
  return (

    <div>

    <div class="page-divide py-4"
      style={{
        backgroundColor: '#FF6A3C',
      }}
    >
    </div>
    <div class="flex container mx-auto py-20">
    <div class="w-1/2 pr-12"><h3>Lets talk</h3></div>
    <div class="w-1/2 pr-12">
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Cras auctor, nisl eget faucibus consequat, ipsum nisi viverra metus, id elementum leo ex a tellus.
    Fusce iaculis magna vel erat ornare congue.</p>
    </div>
    </div>



    </div>


  );
}
export default Callout
